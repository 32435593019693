$primary-color: #36484b;
:root {
  --ant-primary-color: #4f6367; // Primary color for buttons, links, etc.
  --ant-success-color: #52c41a;
  --ant-error-color: #ff4d4f;
  --ant-warning-color: #faad14;
}

.ant-btn-primary {
  background-color: var(--ant-primary-color);
  border-color: var(--ant-primary-color);
}

.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 33px;
}

.ant-layout-content {
  padding: 4px;
  min-height: 280px;
}

// src/App.scss
$primary-color: #4f6367;
$secondary-color: #4d53ff;
$text-color: #333;
$cta-bg: $secondary-color;
$cta-hover-bg: #fff48e; // Fixed color for hover state

.layout {
  min-height: 100vh;
}

.logocontainer {
  display: flex;
  justify-content: center;
  padding: 30px;
  width: 100%;
  position: relative;
  overflow: hidden;

  .logohouse {
    padding: 2px 30px;
  }
}
.logoimg {
  width: 120px;
  height: 66.93px;
  background-image: url("../public/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* Header Styling */
.app-header {
  justify-content: space-between;
  background: #ffffff;
  padding: 0 5px;
  position: relative;
  width: 100%;
  float: left;
  .logohouse {
    background-color: transparent !important;
    .logoimg {
      width: 80px !important;
      height: 44.62px !important;
    }
  }

  &.supplier {
    background: #200546;
    color: #fff;
    padding: 0 5px;
    position: relative;
    width: 100%;
    float: left;
    .logohouse {
      background-color: transparent !important;
    }
    .logoimg {
      background-image: url("../public/logo.w.png");
      width: 90px !important;
      height: 50.2px !important;
    }
    .ant-badge,
    .anticon,
    .anticon-search,
    .anticon-shopping-cart,
    a {
      color: #fff !important;
    }
    .ant-select-selector a {
      color: #000 !important;
      font-weight: 500;
      text-decoration: none;
      transition: color 0.3s;
    }
  }
  .ant-badge {
    color: #000;
    text-transform: capitalize;
    .anticon {
      color: #000;
    }
  }
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    text-decoration: none;

    &:hover {
      color: #fff48e;
    }
  }

  .desktop-navigation {
    display: flex;
    align-items: center;
    gap: 10px;

    a {
      color: #000;
      font-weight: 500;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        text-decoration: underline;
        color: #fff48e;
      }
    }
    .ant-select-selection-item {
      a {
        color: #0e0d0d;
        font-weight: 500;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: $secondary-color;
          cursor: pointer !important;
        }
      }
    }
    .ant-select {
      width: 100px;
      input {
        color: #0e0d0d;
        font-weight: 500;
        text-decoration: none;
        transition: color 0.3s;
        margin-top: 10px;
      }
    }
    .ant-btn-primary,
    .ant-btn-danger {
      background-color: $cta-bg;
      border: none;
      &:hover {
        background-color: $cta-hover-bg;
      }
    }
  }

  .mobile-menu-icon {
    display: none;
    font-size: 24px;
    color: #000;
    cursor: pointer;
  }

  /* Hide desktop navigation and show mobile menu icon on smaller screens */
  @media (max-width: 1100px) {
    .desktop-navigation {
      display: none;
    }
    .mobile-menu-icon {
      display: block;
    }
  }

  .logocontainer {
    display: flex;
    justify-content: center;
    padding: 0px;
    width: 100%;
    position: relative;
    overflow: hidden;

    .logohouse {
      background-color: #4f6367;
      padding: 2px 0px;
    }
  }

  .animated-text {
    position: relative;
    opacity: 0;
    animation: moveToCenter 2s forwards;
  }

  .shopperlogo {
    animation-delay: 0.5s;
    color: #ff0;
    font-size: 20px;
    font-weight: bold;
  }

  .qtlogo {
    animation-delay: 1s;
    font-size: 29px;
    color: #000;
    font-weight: bold;
  }

  @keyframes moveToCenter {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      transform: translateX(50%);
    }
  }

  .qt {
    transform: translateX(100%);
  }

  .logocontainer .animated-text.qtlogo {
    animation: moveToCenter 2s forwards;
    animation-delay: 1s;
  }
}

/* Drawer Styles */
.mobile-drawer {
  .ant-drawer-title {
    font-weight: bold;
    color: $primary-color;
  }
  a {
    display: block;
    padding: 10px 0;
    color: $primary-color;
    text-decoration: none;
    font-size: 18px;

    &:hover {
      text-decoration: underline;
      color: $secondary-color;
    }
  }
}
.about-card {
  padding: 20px;
}
.hero-section {
  // background: url('/path/to/optimized-image.jpg') center/cover no-repeat;
  background-color: #ffee00;
  .hero-content {
    text-align: center;
    .hero-title {
      font-size: 48px;
    }
    .cta-button {
      background-color: $cta-bg;
      &:hover {
        background-color: $cta-hover-bg;
      }
    }
  }
}

.features-section {
  padding: 40px 20px;
  .feature-card {
    .feature-icon {
      font-size: 48px;
      color: $secondary-color;
    }
  }
}

.add-to-cart-button {
  background-color: $cta-hover-bg;
  border-color: transparent;
  border-radius: 60px;
  color: #000;
  margin-top: 20px;
  &:hover {
    background-color: $cta-hover-bg;
  }
}
/* Footer Styling */
.app-footer {
  color: #4f6367;
  padding: 40px 20px;

  h3 {
    color: #4f6367;
  }

  a {
    color: #4d53ff;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #ff7070; // Fixed hover color
      text-decoration: underline;
    }
  }

  /* Flex layout for desktop */
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
  }

  /* Responsive adjustments for mobile */
  @media (max-width: 1000px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 10px;
    }

    .footer-section {
      width: 100%;
      padding: 10px 0;
    }
  }
}

input,
.ant-form-item-control-input {
  max-width: 540px !important;
}

textarea {
  max-width: 540px !important;
}

/* Style for active navigation link */
.active-link {
  color: #1890ff; /* Ant Design primary color */
  font-weight: bold;
  border-bottom: 2px solid #fff48e;
}

/* Optional: Hover effect */
.desktop-navigation a:hover,
.mobile-drawer a:hover {
  color: #40a9ff;
}

/* Additional styling for navigation links */
.desktop-navigation a,
.mobile-drawer a {
  margin-right: 0px;
  color: rgba(255, 255, 255, 0.85);
  transition: color 0.3s ease;
  display: block;
}
.mobile-drawer a {
  color: rgba(0, 0, 0, 0.85);
}

/* Adjust Button inside NavLink */
.desktop-navigation .ant-btn {
  margin-right: 10px;
}

.home-section {
  .hero-section {
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 0px 0px;
    text-align: center;
    color: white;

    .hero-overlay {
      background: rgba(0, 0, 0, 0.5);
      padding: 40px;
    }

    .hero-title {
      font-size: 48px;
      color: #fff;
      margin-bottom: 20px;
    }

    .hero-subtitle {
      font-size: 20px;
      color: #ddd;
      margin-bottom: 20px;
    }

    .cta-button {
      background-color: #1890ff;
      color: #fff;
      font-size: 18px;
    }
  }

  .features-section {
    margin-top: 40px;

    .feature-card {
      text-align: center;
      padding: 20px;
      .feature-icon {
        font-size: 48px;
        color: #1890ff;
      }
    }
  }

  .popular-products-section {
    .product-card {
      .product-card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.product-card-title {
  color: #4f6367;
  font-size: 13px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top: none;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.suggestion-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  .suggestion-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 4px;
    border: 2px solid #d9d9d9;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}

.search-bar {
  flex: 1;
}

.global-search-input {
  border-radius: 300px !important;
  width: 100%;
  margin-left: 30px;
  max-width: 400px;
  min-width: 320px;
}

.global-search-button {
  background-color: transparent;
  border: none;
  color: #000;
  border-radius: 0;
  padding: 0 10px;
  margin-left: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #36484b;
  }
}
.full-search-view {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  min-height: 30vh;
  border-radius: 0;
  z-index: 100000;
}
.close-search-view {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
}

.logo-link {
  color: #fff !important;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.edit-cart-link {
  margin-left: 20px;
  text-align: left;
  color: orange;
  a {
    color: orange;
  }
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
/* Customize table borders */
.ant-table {
  border: 2px solid #ccc; /* Custom table border */
}

/* Customize table header */
.ant-table-thead > tr > th {
  background-color: #4f6367; /* Custom background color */
  color: #333; /* Custom text color */
  border-bottom: 2px solid #aaa; /* Custom bottom border */
  font-weight: bold; /* Custom font style */
}

/* Customize table row */
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #ddd; /* Custom row border */
  background-color: #fafafa; /* Custom cell background */
}

/* Customize hover effect */
.ant-table-tbody > tr:hover > td {
  background-color: #e6f7ff; /* Custom hover background */
}

.login-form {
  border: 2px solid #dedede;
  margin: 0 auto;
  max-width: 600px;
  padding: 10px 20px 40px;
  border-radius: 8px;
  margin-bottom: 150px;
  .ant-btn {
    width: 100%;
    padding: 10px;
    height: 40px;
    background-color: #f6e676;
    color: #333;
    border: none;
    box-shadow: 0 2px 2px #00000033;
  }
  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 8px;
    height: 40px;
    border: 1px solid #b8b8b8;
  }
}

.animated-text {
  position: relative;
  opacity: 0;
  animation: moveToCenter 2s forwards;
}

.shopperlogo {
  animation-delay: 0.5s;
  color: #ff0;
  font-size: 30px;
  font-weight: bold;
}

.qtlogo {
  animation-delay: 1s;
  font-size: 33px;
  color: #fff;
  font-weight: bold;
}

@keyframes moveToCenter {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    transform: translateX(50%);
  }
}

.qt {
  transform: translateX(100%);
}

.logocontainer .animated-text.qtlogo {
  animation: moveToCenter 2s forwards;
  animation-delay: 1s;
}

.ant-spin-dot-item {
  background-color: #4f6367 !important;
}

a {
  cursor: pointer !important;
}

.cart-link {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  margin-right: 20px !important;
  &:hover {
    color: #fff48e;
  }
}
.mobile-drawer {
  .cart-link,
  span {
    color: #000 !important;
  }
  .active-link {
    color: #000 !important;
    background-color: #f0f0f0;
  }
}
.ant-table {
  line-height: auto;
  .ant-table-cell {
    padding: 3px 5px !important;
    font-size: 12px !important;
  }
}

.order-summary {
  // background: rgb(250, 250, 201);
  border: 1px solid #ccc;
  .ant-descriptions-item-label {
    color: #000 !important;
    font-weight: bold;
  }
}

.orangebg {
  background-color: #ff8c00;
}
.redbg {
  background-color: #f86d6d;
  color: #fff;
}

.marginleft40 {
  margin-left: 40px;
}

.floatright {
  float: right;
}
.productlist-options {
  button,
  .ant-input-affix-wrapper {
    margin-right: 20px !important;
  }
}
.main-content {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  background-color: transparent;
}

// for media smaller that 600px
@media (max-width: 600px) {
  .global-search-input {
    margin-left: 0;
    min-width: 0;
  }

  .shopperlogo {
    font-size: 90% !important;
  }
  .qtlogo {
    font-size: 100% !important;
  }
  .ant-card-body {
    padding: 5px !important;
  }
}
.ant-card-body {
  padding: 5px !important;
}

.dimensions {
  .ant-form-item-control-input-content {
    display: grid;
    gap: 5px;
    float: left;
  }
}

.publish-button {
  color: blue;
  &.published {
    color: rgb(255, 145, 0);
  }
  &.unpublished {
  }
}

.banner-left-image,
.banner-right-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 200px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .banner-right-image,
  .banner-right-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner-right-image {
    display: none;
  }
}

.bulk-upload-container {
  padding: 20px;
  background-color: #f0f2f5;

  .upload-card {
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    .ant-card-body {
      padding: 24px;
    }
  }

  .ant-btn {
    font-size: 16px;
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }

  .ant-table-thead > tr > th {
    background-color: #1890ff;
    color: white;
  }

  .ant-table {
    background-color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .ant-modal-header {
    background-color: #1890ff;
    color: white;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
}

.layout-landing {
  /* MarketplaceLandingPage.css */

  .hero-title {
    font-size: 2em;
  }

  .hero-paragraph {
    font-size: 1.1em;
    color: #555;
  }

  .section-title {
    font-size: 1.8em;
  }

  .benefit-title,
  .how-it-works-title {
    font-size: 1.4em;
    margin-bottom: 20px;
  }

  .benefit-card,
  .how-it-works-card {
    padding: 20px;
  }

  .benefit-list,
  .how-it-works-list {
    list-style: none;
    padding: 0;
    line-height: 1.8;
  }

  .benefit-icon {
    color: #52c41a;
    margin-right: 8px;
    font-size: 18px;
  }

  /* Media Queries for Responsiveness */

  @media (max-width: 768px) {
    .hero-title {
      font-size: 1.6em;
    }
    .hero-paragraph {
      font-size: 1em;
    }
    .section-title {
      font-size: 1.5em;
    }
    .benefit-title,
    .how-it-works-title {
      font-size: 1.2em;
    }
    .cta-paragraph {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 450px) {
  .cart-quantity-btn {
    width: 10px;
    padding: 0px 10px;
  }
}

.supplier-benefits-pointers {
  padding: 0;
  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
    padding-bottom: 10px;
    align-items: center;
  }
}

.ant-input-outlined {
  border-color: #aaa !important;
  &.editable-cell {
    &.editable-cell-error {
      border-color: #ff4d4f !important;
    }
  }
}
.ant-upload-list-item {
  background-color: #f0f2f5;
}

.bulkimages {
  font-size: 12px;
  color: #000;
  padding-left: 10px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #d8fad0;
  margin-top: -5px;
  padding-top: 5px;
}

.pinfo-card{
  // style={{
  //   width: "100%",
  //   padding: 5,
  //   marginBottom: 20,
  //   marginRight: 10,
  // }}
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
}

.pricing-card{
  // style={{
  //   width: "100%",
  //   marginTop: 10,
  //   padding: 5,
  //   marginLeft: 10,
  // }}
  width: 100%;
  padding: 5px;
  margin-left: 10px;
}

@media screen and (max-width: 765px) {

  .pricing-card{
    margin-left: 0;

  }
  
}