.landing-page {
  .landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for header */
    padding: 0px;
  }
  .category-filter {
    margin: 20px auto;
    text-align: center;
    font-weight: bold;
    color: #333;
  }

  .category-card {
    text-align: center;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 0px !important;
    transition: box-shadow 0.3s, transform 0.3s ease;
    width: 100%;
    height: 130px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      transform: translateY(-5px); /* Make the category card feel lifted */
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }
    .ant-card,
    .ant-card-body {
      padding: 0px !important;
    }
    img {
      width: 100%;
    }
  }

  .product-card {
    text-align: left;
    transition: box-shadow 0.3s;
    padding: 5px;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px); /* Card elevation on hover */
      background-color: #f7f7f7;
    }

    .ant-card-cover img {
      border-radius: 8px;
      transition: transform 0.3s ease;
      object-fit: cover;
      height: 200px;
    }

    .ant-btn {
      margin-top: 10px;
    }
  }

  .landing-footer {
    text-align: center;
    padding: 10px;
    background-color: #f0f2f5;
  }
  /* Add animations to the cards on page load */
  .category-card,
  .product-card {
    animation: fadeInUp 1s ease-out;
  }
  /* Keyframe animation for fade-in effect */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.cat-parent{
  // padding: 0px !important;
}

.category-card {
  transition: all 0.3s ease;

  /* Default styling for categories */
  &.automotive {
    background-color: #f1f5f8;
  }
  &.baby-kids {
    background-color: #ffe3e5;
  }
  &.beauty {
    background-color: #fff5f8;
  }
  &.electronics {
    background-color: #e0f7fa;
  }
  &.food-beverages {
    background-color: #f2f4f7;
  }
  &.furniture {
    background-color: #ffe0b2;
  }
  &.health-wellness {
    background-color: #e8f5e9;
  }
  &.home-appliances {
    background-color: #f1f8e9;
  }
  &.jewelry-accessories {
    background-color: #f8bbd0;
  }
  &.kitchenware {
    background-color: #fff9c4;
  }
  &.office-supplies {
    background-color: #c8e6c9;
  }
  &.personal-care {
    background-color: #fbe9e7;
  }
  &.pet-supplies {
    background-color: #f1f8e9;
  }
  &.sports-outdoors {
    background-color: #e1f5fe;
  }

  &.active {
    background-color: #e6f7ff; // Light blue background for the active card
    border-width: 5px;
  }
}

.text-center {
  text-align: center;
}
/* Add some hover effects for buttons */
.ant-btn {
  &:hover {
    background-color: #40a9ff;
  }
}


.navbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-item {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-right: 20px;
  position: relative;
}

.menu-item:hover {
  color: #1890ff;
}

.arrow-down {
  font-size: 12px;
  margin-left: 5px;
}

.ant-dropdown-menu {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.ant-dropdown-menu-item {
  font-size: 14px;
  padding: 8px 20px;
  color: #333333;
  transition: background-color 0.2s ease-in-out;
}

.ant-dropdown-menu-item:hover {
  background-color: #f0f0f0;
}

.ant-dropdown-menu-item-group-title {
  font-size: 12px;
  font-weight: 700;
  color: #999999;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding: 8px 20px;
}