
@media (max-width: 768px) {
    .responsive-banner {
      .ant-card {
        padding: 20px 0;
      }
  
      .ant-typography {
        font-size: 2rem;
      }
  
      .ant-btn {
        font-size: 14px;
        width: 100%;
      }
    }
  }
  
  @media (min-width: 768px) {
    .responsive-banner {
      .ant-card {
        padding: 40px 0;
      }
  
      .ant-typography {
        font-size: 3rem;
      }
  
      .ant-btn {
        font-size: 16px;
      }
    }
  }